// import { useContext, useEffect, useState } from "react";
// import messageContext, { MessageContext } from "../../Context";
// import { Action, ConnectionType, MessageBase } from "../../types";
// import { toast } from "react-toastify";
// import { useRetirementPlatform } from "../../../Platform/useRetirementPlatform";
// import { IS_DEV } from "../../../../util";

export const UserMessageHandler = (): JSX.Element => {
  // const mc: MessageContext = useContext(messageContext);
  // const { retirementUser, refreshRetirementUser, unloadApp } =
  //   useRetirementPlatform();
  // const [ttsMessages, setTtsMessages] = useState([]);
  // const [ttsMessagesExtra, setTtsMessagesExtra] = useState([]);
  // const [ended, setEnded] = useState(true);
  // const [endedExtra, setEndedExtra] = useState(true);

  // const refreshAccount = async (): Promise<void> => {
  //   if (retirementUser?.retirementJWT) {
  //     refreshRetirementUser(retirementUser?.retirementJWT);
  //   }
  //   unloadApp();
  // };

  // useEffect((): void => {
  //   (async (): Promise<void> => {
  //     if (mc.data.length > 0) {
  //       let rawMessage: any;

  //       try {
  //         rawMessage = JSON.parse(mc.latestData.message);
  //       } catch {
  //         console.error("MessageHandler: Failed to parse payload.");
  //         return;
  //       }

  //       if (!rawMessage?.action) {
  //         console.error("MessageHandler: Invalid payload.");
  //         return;
  //       }

  //       const messageBase = rawMessage as MessageBase;

  //       switch (messageBase.action) {
  //         case Action.Challenge:
  //           mc.sendMessage(
  //             {
  //               challenge: messageBase.challenge,
  //             },
  //             ConnectionType.Local
  //           );
  //           mc.sendMessage(
  //             {
  //               challenge: messageBase.challenge,
  //             },
  //             ConnectionType.Remote
  //           );
  //           break;
  //         case Action.Refresh:
  //           await refreshAccount();
  //           break;
  //         // TODO create action to just refresh the page, for overlay page only
  //         default:
  //           console.debug("MessageHandler: Unknown action.");
  //           break;
  //       }

  //       mc.removeLatestMessage();
  //     }
  //   })();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [mc.data]);

  return <></>;
};

export default UserMessageHandler;
